import React from "react"
import { Link } from "gatsby"
import logo1 from '../assets/logo-af.svg';
import MTweb from '../assets/project-MT-site.png';
import wapo from '../assets/project-wapo.png';
import WI from '../assets/WI.png';
import MTholiday from '../assets/project-xmas-card.png';
import admin2 from '../assets/Admin-roster.png';
import admin1 from '../assets/Admin-UIkit.png';
import samAdams from '../assets/sam-adams.png';
import stockup from '../assets/stockup-personas.png';
import violin from '../assets/violin-cat.png';
import serviceYear from '../assets/project-service-year.png';
import robotHeads from '../assets/project-robot-heads.png';
import ali from '../assets/ali-pic.png';
import aliIllustration from '../assets/ali-images.png';
import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section>
        <div className="row">
          <h1 className="header--large align-center portfolio-title">Portfolio</h1>
          <div className="row-projects">
            <div className="column column1 row-sm row-padding">
              <div className="project">
                <Link to="/MT-marketing/">
                  <img className="withShadow" src={MTweb} alt="Mariana Tek Site Design" />
                </Link>
              </div>
              <div className="project">
                <Link to="/WAPO/">
                  <img className="withShadow" src={wapo} alt="Washington Post Election" />
                </Link>
              </div>
            </div>
            <div className="column column2 row-lg">
              <div className="project">
                <Link to="/MT-web-integrations/">
                  <img className="project-with-border withShadow project-radius" src={WI} alt="Mariana Tek Web Integrations" />
                </Link>
              </div>
              <div className="project">
                <Link to="/MT-illustrations/">
                  <img className="withShadow" src={MTholiday} alt="Mariana Tek Holiday Card" />
                </Link>
              </div>
            </div>
          </div>
          <Link className="full-width-project bg-purple row-projects project" to="/MT-admin/">
            <div className="row-padding col-9 radius-lg home-admin-1">
              <img className="withShadow" src={admin1} alt="Mariana Tek Site Design" />
            </div>
            <div className="col-4">
              <img className="withShadow" src={admin2} alt="Washington Post Election" />
            </div>
          </Link>
          <div className="row-projects">
            <div className="column column1 row-sm row-padding">
              <div className="project">
                <Link to="/service-year">
                  <img className="withShadow" src={serviceYear} alt="Service Year Website" />
                </Link>
              </div>
              <div className="project">
                <Link to="/sam-adams/">
                  <img className="withShadow" src={samAdams} alt="Sam Adams Market Research" />
                </Link>
              </div>
              <div className="project">

              </div>
            </div>
            <div className="column column2 row-lg">
              <div>
                <div className="project radius-lg">
                  <Link to="/ali-illustrations/">
                    <img className="withShadow" src={aliIllustration} alt="Illustration of me designing and cooking" />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="row-half row-padding-md">
                  <div className="project">
                    <Link to="/violin-cat/">
                      <img className="withShadow" src={violin} alt="Illustration of cat playing a violin" />
                    </Link>
                  </div>
                </div>
                <div className="row-half">
                  <div className="project">
                    <Link to="/robot-heads/">
                      <img className="project-with-border withShadow project-radius" src={robotHeads} alt="Illustrated heads to look like robots" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-yellow fullwidth-cta">
        <div className="row">
          <div className="relative">
            <div className="cta">
              <div className="margin-btm-sm">
                <h2 className="header--large text-centered">Want to learn more?</h2>
                <p className="text-centered">Want to get more information or say hello? Reach out below, I'd love to hear from you!</p>
              </div>
              <a className="button button-group" href="mailto:alifelski@gmail.com">Say Hello!</a>
              <a className="button" target="_blank" href="https://www.linkedin.com/in/alexandra-felski-6626464/">View Resume</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
